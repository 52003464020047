<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            {{ $t("message.qualificationLevels") }}
                        </div>
                        <div class="filter-internal-block">
                            <crm-input
                                :size="'small'"
                                :className="'w100'"
                                v-model="filterForm.search"
                                :icon="'el-icon-search'"
                            ></crm-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <crm-create-and-column-settings
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/settings' }">
                    {{ $t("message.settings") }}
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{ $t("message.qualificationLevels") }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.level.show">
                            {{ columns.level.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.level.show">
                            <crm-input
                                :placeholder="columns.level.title"
                                v-model="filterForm.level"
                            ></crm-input>
                        </th>

                        <th v-if="columns.created_at.show">
                            <crm-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                            ></crm-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="qualificationLevels in list"
                        :key="qualificationLevels.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">
                            {{ qualificationLevels.id }}
                        </td>

                        <td v-if="columns.level.show">
                            {{ qualificationLevels.level }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ qualificationLevels.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ qualificationLevels.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="qualificationLevels"
                                :permissionShow="'qualificationLevelsController@update'"
                                :permissionDestroy="'qualificationLevelsController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                size="70%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "qualificationLevels",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {
            filterForm: {},
        };
    },
    computed: {
        ...mapGetters({
            list: "qualificationLevels/list",
            columns: "qualificationLevels/columns",
            pagination: "qualificationLevels/pagination",
            statues: "qualificationLevels/statues",
            filter: "qualificationLevels/filter",
            sort: "qualificationLevels/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "qualificationLevels/index",
            setPagination: "qualificationLevels/setPagination",
            updateSort: "qualificationLevels/updateSort",
            updateFilter: "qualificationLevels/updateFilter",
            updateColumn: "qualificationLevels/updateColumn",
            updatePagination: "qualificationLevels/updatePagination",
            show: "qualificationLevels/show",
            empty: "qualificationLevels/empty",
            delete: "qualificationLevels/destroy",
            refreshData: "qualificationLevels/refreshData",
        }),
    },
};
</script>
